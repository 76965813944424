import axios from 'axios'
import router from '../../router'
import constants from '../../config/constants'
// import store from '../store'

export default {
  horizontalMenuAction (context, payload) {
    if (context.state.horizontalMenu) {
      context.commit('horizontalMenuCommit', false)
    } else {
      context.commit('horizontalMenuCommit', true)
    }
  },
  miniSidebarAction (context, payload) {
    return new Promise((resolve, reject) => {
      if (context.state.miniSidebarMenu) {
        context.commit('miniSidebarCommit', false)
        resolve(false)
      } else {
        context.commit('miniSidebarCommit', true)
        resolve(true)
      }
    })
  },

  authUserAction (context, payload) {
    // context.commit('authUserCommit', { authInProgress: true })
    context.commit('showLoaderCommit', { authInProgress: true })
    axios.post(constants.loginURL, payload).then((response) => {
      context.commit('authUserCommit', response.data)
      // check if there is a redirect query
      if (router.history.current.query.redirect) {
        // if there is a redirect query, redirect to the page
        router.replace(router.history.current.query.redirect)
      } else {
        //  go to the home page
        router.replace('/home')
      }
    }).catch(({ response }) => {
      const result = { error: true, details: response.data }
      context.commit('authUserCommit', result)
    })
  },
  refreshToken (context) {
    const refreshToken = { refresh: context.state.authUser.refresh_token }
    axios.post(constants.refreshUrl, refreshToken).then((response) => {
      context.commit('setTokensCommit', { access_token: response.data.access, refresh_token: response.data.refresh })
      sessionStorage.setItem('access_token_expires', JSON.stringify(response.data.access_token_expiration))
    }).catch(({ response }) => {
      const result = { error: true, details: response.data }
      context.commit('authUserCommit', result)
    })
  },
  verifyToken (context) {
    const accessToken = { access: context.state.authUser.access_token }
    axios.post(constants.verifyTokenUrl, accessToken).then((response) => {
      if (response.data !== {}) {
        context.dispatch('refreshToken')
      }
    }).catch(({ response }) => {
      const result = { error: true, details: response.data }
      context.commit('authUserCommit', result)
    })
  },
  logoutUserAction (context) {
    context.commit('logoutUserCommit')
    router.replace('/auth/sign-in')
  },
  addUserAction (context, payload) {
    axios.post(constants.registrationURL, payload)
      .then((response) => {
        context.commit('authUserCommit', response.data)
        // check if there is a redirect query
        if (router.history.current.query.redirect) {
        // if there is a redirect query, redirect to the page
          router.replace(router.history.current.query.redirect)
        } else {
        //  go to the home page
          router.replace('/home')
        }
      }
      ).catch(({ response }) => {
        const result = { error: true, details: response.data }
        context.commit('authUserCommit', result)
      })
  },
  activePageAction (context, payload) {
    context.commit('activePageCommit', payload)
  },
  addBookmarkAction (context, payload) {
    context.commit('addBookmarkCommit', payload)
  },
  removeBookmarkAction (context, payload) {
    context.commit('removeBookmarkCommit', payload)
  },
  setLangAction (context, payload) {
    context.commit('setLangCommit', payload)
  },
  layoutModeAction (context, payload) {
    let dark = payload.dark ? 'dark' : 'light'
    let rtl = payload.rtl ? 'rtl' : 'ltr'
    document.getElementsByTagName('html')[0].setAttribute('mode', dark)
    document.getElementsByTagName('html')[0].setAttribute('dir', rtl)
    context.commit('layoutModeCommit', {
      dark: payload.dark,
      rtl: payload.rtl,
      sidebar: ''
    })
  }
}
