import axios from 'axios'
import store from '../store'

import constants from '../config/constants'

export default function axiosSetup () {
  axios.defaults.headers.get.Accepts = 'application/json'
  axios.defaults.baseURL = constants.baseUrl

  // request interceptor
  axios.interceptors.request.use(
    config => {
      // if request is not login or registration, set base url and add token to header
      if (!config.url.includes('login') && !config.url.includes('registration')) {
        axios.defaults.baseURL = constants.baseUrl
        const token = store.state.Setting.authUser.access_token
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  // response interceptor
  axios.interceptors.response.use(

    response => {
      // status codes within 200-299 are considered successful
      return response
    },
    error => {
      const originalRequest = error.config
      // if error is 401, refresh token
      if (error.response.status === 401 && originalRequest.url.includes('refresh')) {
        store.dispatch('logoutUserAction')

        return Promise.reject(error)
      } else if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        store.dispatch('Setting/refreshToken')

        return axios(originalRequest)
      }
      return Promise.reject(error)
    }
  )
}
