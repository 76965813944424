const baseUrl = 'https://urbanlibraryapi.marvinbuilds.com'
// const baseUrl = 'http://127.0.0.1:8000'

export default {
  webBaseURL: process.env.MIX_APP_URL ? process.env.MIX_APP_URL : '127.0.0.1:8000/api/',
  registrationURL: `${baseUrl}/api/account-auth/registration/`,
  loginURL: `${baseUrl}/api/account-auth/login/`,
  refreshUrl: `${baseUrl}/api/account-auth/token/refresh/`,
  verifyTokenUrl: `${baseUrl}/api/account-auth/token/verify/`,
  baseUrl: `${baseUrl}/api/`
}
