export default {
  horizontalMenuCommit (state, data) {
    state.horizontalMenu = data
  },
  miniSidebarCommit (state, data) {
    state.miniSidebarMenu = data
  },
  authUserCommit (state, data) {
    if (data.error) {
      state.authUser.error = data.details
      return
    }
    state.authUser.error = null
    state.authUser.user = data.user
    state.authUser.auth = true
    state.authUser.authInProgress = false
    state.authUser.access_token = data.access_token
    state.authUser.refresh_token = data.refresh_token
  },
  showLoaderCommit (state, data) {
    state.authUser.authInProgress = data.authInProgress
  },
  logoutUserCommit (state) {
    state.authUser.user = null
    state.authUser.auth = false
    state.authUser.authInProgress = false
    state.authUser.access_token = null
    state.authUser.refresh_token = null
  },
  setTokensCommit (state, data) {
    state.authUser.access_token = data.access_token
    state.authUser.refresh_token = data.refresh_token
  },
  addUserCommit (state, data) {
    state.users.push(data)
  },
  activePageCommit (state, data) {
    state.activePage = data
  },
  addBookmarkCommit (state, data) {
    state.bookmark.push(data)
  },
  removeBookmarkCommit (state, data) {
    let book = state.bookmark.findIndex(item => item.link.name === data.link.name)
    if (book !== -1) {
      state.bookmark.splice(book, 1)
    }
  },
  setLangCommit (state, data) {
    state.lang = data
  },
  layoutModeCommit (state, data) {
    state.layoutMode = data
  }
}
